import { useState, useEffect } from 'react';
import useBreaks from '../hooks/useBreaks';

import { Input, Select, Textarea, Checkbox, Text } from '@chakra-ui/react';
import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';

// plugins
import axios from 'axios';
import isEmail from 'email-validator';

export default function Page(){
    const mq = useBreaks();

    const [ready,setReady] = useState(false);
    const [thanks,setThanks] = useState(false);
    const [processing,setProcessing] = useState(false);
    const [form,setForm] = useState({
        name:'',
        surname:'',
        email:'',
        phone:'',
        type:'',
        message:'',
        location:'',
        gdpr:'false'
    });

    const onChange = (e) => {
        const { name, value, checked } = e.target;
        if(name === 'gdpr'){
            setForm(f => ({
                ...f,
                gdpr:checked ? 'true':'false'
            }));
        }else {
            setForm(f => ({
                ...f,
                [name]:value
            }));
        }
    }

    useEffect(() => {
        const empty = Object.entries(form).find(item => item[0] !== 'location' && item[1] === '') ? true:false;
        const email = form.email !== '' && isEmail.validate(form.email);
        const phone = !isNaN(form.phone);
        const gdpr = form.gdpr === 'true';
        setReady(!empty && email && phone && gdpr);
    }, [form])
    

    const onSubmit = (e) => {
        if(ready){
            e.preventDefault();
            e.stopPropagation();
            setProcessing(true);
            axios({
		        method: 'post',
		        responseType: 'json',
		        url: 'https://rest.audimiddleeast.com/group/send.php',
		        data: {
                    name:form.name,
                    surname:form.surname,
                    email:form.email,
                    phone:form.phone,
                    type:form.type,
                    message:form.message,
                    location:form.location,
                    gdpr:form.gdpr
			  	},
			  	headers: {
			  		'authorization':'7e44fd4bf570f256ce66fdd2f3a5d13PRJT'
			  	}
		    }).then((res)=>{
		    	if(res.data === 'success'){
                    setThanks(true);
                    setTimeout(()=>{
                        setProcessing(false);
                    },250);
		    	}else {
		    		alert("Somethings not right: please try again later");
                    setProcessing(false);
		    	}
		    }).catch((err)=>{
                console.log(err)
		    	alert("Somethings not right: please try again later");
                setProcessing(false);
		    }) 
        }
    }

	return (
		<div className="page article">
            <div className="ui-banner">
                <div className="media flip">
                    <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Mountains_darkgreen.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>Contact us.</GroupuiHeadline>
                    <GroupuiText>Send us a message for more info on a specific topic below.</GroupuiText>
                </div>
            </div>
            {
            !thanks ?
            <>
            <div className="ui-form">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Bold" marketing={false}>Make an enquiry today.</GroupuiHeadline>
                    <GroupuiText>Send us an enquiry below or contact us at <a href="mailto:customer.care@vwgme.com" className="text-link">customer.care@vwgme.com</a>, and our team will be in contact with more information.</GroupuiText>
                    <form>
                        <div className="ui-form-row">
                            <div className="ui-form-column">
                                <div className="ui-form-element">
                                    <Text fontSize="sm" fontWeight="bold" mb="1">First name</Text>
                                    <Input name="name" borderColor="#a8adb3" required value={form.name} onChange={onChange}/>
                                </div>
                            </div>
                            <div className="ui-form-column">
                                <div className="ui-form-element">
                                    <Text fontSize="sm" fontWeight="bold" mb="1">Last name</Text>
                                    <Input name="surname" borderColor="#a8adb3" required value={form.surname} onChange={onChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="ui-form-row">
                            <div className="ui-form-column">
                                <div className="ui-form-element">
                                    <Text fontSize="sm" fontWeight="bold" mb="1">Email address</Text>
                                    <Input name="email" type="email" borderColor="#a8adb3" required value={form.email} isInvalid={form.email !== '' && !isEmail.validate(form.email)} onChange={onChange}/>
                                </div>
                            </div>
                            <div className="ui-form-column">
                                <div className="ui-form-element">
                                    <Text fontSize="sm" fontWeight="bold" mb="1">Phone number</Text>
                                    <Input name="phone" type="tel" pattern="[0-9.]*" borderColor="#a8adb3" required value={form.phone} isInvalid={isNaN(form.phone)} onChange={onChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="ui-form-row">
                            <div className="ui-form-column">
                                <div className="ui-form-element">
                                    <Text fontSize="sm" fontWeight="bold" mb="1">Enquiry type</Text>
                                    <Select name="type" borderColor="#a8adb3" required value={form.type} onChange={onChange}>
                                        <option value="" disabled></option>
                                        <option value="sales">Sales</option>
                                        <option value="aftersales">After-sales</option>
                                        <option value="other">Other</option>   
                                    </Select>
                                </div>
                            </div>
                            <div className="ui-form-column spacer"></div>
                        </div>
                        <div className="ui-form-row">
                            <div className="ui-form-column grow">
                                <div className="ui-form-element">
                                    <Text fontSize="sm" fontWeight="bold" mb="1">Your message</Text>
                                    <Textarea name="message" borderColor="#a8adb3" resize="none" rows={mq.tablet ? 5:8} required value={form.message} onChange={onChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="ui-form-row" hidden>
                            <div className="ui-form-column grow">
                                <div className="ui-form-element">
                                    <Text fontSize="sm" fontWeight="bold" mb="1">Location</Text>
                                    <Input name="location" borderColor="#a8adb3" value={form.location} onChange={onChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="ui-form-row">
                            <div className="ui-form-column grow">
                                <Checkbox name="gdpr" required value={form.gdpr} onChange={onChange}>
                                    I have read and accept the conditions specified in the <a href="https://www.volkswagen-group.com/en/privacy-policy-15677" target="_blank" rel="noreferrer" className="ui-inline-link">privacy policy</a>
                                </Checkbox>
                            </div>
                        </div>
                        <div className="ui-form-submit">
                            <button type="submit" className="cta" disabled={!ready} onClick={onSubmit}>{processing ? 'Processing':'Send request'}</button>
                        </div>
                        {
                            !ready && <Text fontSize="xs" color="#db0034" fontWeight="bold">Please fill in a valid email address, phone number, and make sure the checkbox is checked.</Text>
                        }
                        <div className="ui-disclaimer">
                            <GroupuiText size="caption">All personal data provided through this form may be used and retained by Volkswagen Group Middle East, its authorized dealers and its service providers for promotional or marketing purposes.</GroupuiText>
                        </div>
                    </form>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Location:</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            {/* <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Bold" marketing={false}>To contact us, please email <a href="mailto:customer.care@vwgme.com" className="text-link">customer.care@vwgme.com.</a></GroupuiHeadline> */}
                            <GroupuiText>
                            Volkswagen Group Middle East QFZ LLC<br/>
                            P.O. Box 24164, Doha, Qatar<br/>
                            <br/><br/>
                            Volskwagen Group Sales Middle East FZCO<br/>
                            Dubai Airport Freezone<br/>
                            P.O. Box 27758, Dubai, U.A.E.
                            </GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            </>
            :
            <div className="ui-thanks">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Bold" marketing={false}>Thank you for your enquiry.</GroupuiHeadline>
                    <br/>
                    <GroupuiText>A member of our team will review your request and be in contact with next steps.</GroupuiText>
                </div>
            </div>
            }
        </div>
	);
}
