import useBreaks from '../hooks/useBreaks';

import { GroupuiHeadline, GroupuiText, GroupuiAccordion } from '@group-ui/group-ui-react';

export default function Page(){
    const mq = useBreaks();

	return (
		<div className="page">
            {/* banner */}
            <div className="ui-banner">
                <div className="media">
                    <img src={`${process.env.PUBLIC_URL}/assets/img/careers/banner/working-with-us.jpg`} alt=""/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>Working <span className="ui-neon-text">with us.</span></GroupuiHeadline>
                    <GroupuiText>Welcome to Volkswagen Group Middle East, where innovation meets opportunity.</GroupuiText>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Join our diverse team.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/careers/lead.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Unleash your potential with us.</GroupuiHeadline>
                            <GroupuiText>At Volkswagen Group Middle East, we pride ourselves on talented and driven professionals from all walks of life; we forge partnerships with diverse, passionate professionals. As a global leader in the automotive industry, we offer a broad spectrum of career options that cater to various talents and ambitions.</GroupuiText>
                            <a href="https://careers.volkswagen-me.info/index.php/job-listings" target="_blank" rel="noreferrer" className="cta">Discover Jobs</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-accordion ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Embark on your professional journey with us.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Whether you are passionate about driving sales, shaping marketing strategies, developing dealer networks, leading digital transformation, pioneering electrification or optimizing aftersales services, we have the perfect role.</GroupuiHeadline>
                            <br/>
                            <GroupuiAccordion first={true} expanded={true} size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">Sales</GroupuiHeadline></span>
                                <GroupuiText>Sales are at the forefront of our customer engagement, driving growth and forging strong relationships across our extensive network. Our sales team is vital in expanding our market presence and delivering exceptional service.</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">Marketing</GroupuiHeadline></span>
                                <GroupuiText>Join our dynamic marketing team to create compelling campaigns that resonate with our audience. Your creativity will help us connect with customers in innovative ways, from brand management to digital marketing.</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">Dealer development</GroupuiHeadline></span>
                                <GroupuiText>Dealer Development plays a crucial role in strengthening our dealership network. By enhancing dealer performance and ensuring customer satisfaction, you’ll contribute to our reputation for excellence and reliability.</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion first={true}>
                                <span slot="headline"><GroupuiHeadline heading="h6">Digital</GroupuiHeadline></span>
                                <GroupuiText>Embrace the future with our digital team, where technology and creativity intersect. Work on cutting-edge projects that enhance our digital presence and revolutionize our customers' automotive experience.</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">Electrification</GroupuiHeadline></span>
                                <GroupuiText>Electrification is a part of the sustainable revolution. Our electrification team is dedicated to developing and promoting electric vehicles, making strides towards a greener future.</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">Aftersales</GroupuiHeadline></span>
                                <GroupuiText>Aftersales ensures our customers receive unparalleled service and support. Our aftersales team is essential in maintaining the quality and performance of our vehicles and fostering long-term customer loyalty.</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">HR</GroupuiHeadline></span>
                                <GroupuiText>Working in HR at Volkswagen Group Middle East involves supporting our most valuable asset: our people. From recruitment to development, fostering a positive workplace culture, and ensuring compliance, you will play a vital role in nurturing talent and driving organisational success.</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">IT</GroupuiHeadline></span>
                                <GroupuiText>Our IT team tackles technological challenges head-on, from troubleshooting issues to implementing innovative solutions. Staying ahead of trends, problem-solving, and enabling efficient operations through technology are key aspects of this critical role. Join us and help drive our digital transformation.</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">Finance</GroupuiHeadline></span>
                                <GroupuiText>In finance, you will act as the steward of our resources, balancing budgets, and guiding financial strategies. This role requires precision, foresight, and a commitment to empowering the organization through sound financial management. Your expertise will help sustain our growth and stability.</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">Training</GroupuiHeadline></span>
                                <GroupuiText>As part of the training department, you will educate technicians on the latest industry techniques and technologies. Your efforts will empower professionals, foster skill development, and ensure high standards in the service and repair of our vehicles. Contribute to our reputation for excellence and reliability.</GroupuiText>
                            </GroupuiAccordion>
                            <GroupuiAccordion size="l">
                                <span slot="headline"><GroupuiHeadline heading="h6">Logistics</GroupuiHeadline></span>
                                <GroupuiText>Our logistics team orchestrates the efficient movement of vehicles and parts. This role is about coordination, optimization, and ensuring timely delivery to support production and customer satisfaction. Your work will be crucial in maintaining our operational excellence.</GroupuiText>
                            </GroupuiAccordion>
                            <br/>
                            <a href="https://careers.volkswagen-me.info/index.php/job-listings" target="_blank" rel="noreferrer" className="cta">Discover Jobs</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Talent Acquisition – Where potential meets purpose.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiText>Our emphasis is on strategically placing individuals in appropriate roles to fuel growth and triumph. Our objective extends beyond mere job fulfillment; it encompasses constructing a workforce that actively advances our long-term goals. We base our talent acquisition approach on a steadfast dedication to excellence, integrity, diversity, equality, inclusion, and empowering our employees. We aspire to draw in, nurture, and retain the most exceptional talent while creating a culture that appreciates each person's distinct contributions and viewpoints.</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Learning & Development – Unleashing the collective genius.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiText>We believe that the key to sustainable success lies in the collective genius of our workforce, and our Learning & Development (L&D) program serves as the catalyst for innovative thinking and problem-solving. <br/><br/>Our focus on a robust L&D program is a testament to our dedication to excellence.  By providing our people with the tools to excel, fostering a culture of learning, innovation and nurturing future leaders, we build a strong foundation for sustained success and ensure that we remain at the forefront of our industry.  <br/><br/>
                            Our strategic commitment to fostering a culture of continuous learning and development is not just a choice but our imperative. It's not just about staying ahead—it's about defining the frontier of our industry.</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Diversity, Equality & Inclusion – Where our strengths unite.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiText>We proudly affirm that Diversity, Equality, and Inclusion (DEI) are not just aspirations or initiatives but the essence of who we are. DEI is at the forefront of our DNA, and our commitment to these principles is unwavering from the ground floor to the boardroom.  <br/><br/>
                            We believe that our strength lies in our differences, so DEI efforts are deeply ingrained in every facet of our organization. Our culture is one that values and promotes diversity and inclusion at every level, from the individual contributor to the executive leadership team.  We understand that our differences are our strengths, fostering a culture where every voice matters, every perspective is valued, and every individual thrives.</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Internships.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiText>At Volkswagen Group Middle East, we open doors to budding talents, offering Comprehensive Internship Programs designed for fresh graduates with Bachelor's or Advanced Degrees. We seek individuals who not only bring passion and fresh perspectives but also have the drive to shape the future of our business.  <br/><br/>Step into our dynamic world where market trends are not just observed, but where you have the chance to influence them. Join us and work alongside the brightest minds, the boldest ideas, and the most iconic brands. At Volkswagen Group Middle East, you're not just an intern—you're a crucial part of the team shaping the future of mobility.</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Recruitment Fraud Disclaimer.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiText>Candidates for jobs within our organization should note that Volkswagen Group Middle East does NOT request any payment or fees from individuals seeking employment opportunities within our organization. Prospective candidates should be cautious and wary of any communication that asks for personal or financial information or requests payments to secure a job at Volkswagen Group Middle East. In case anyone receives such messages, they should presume they are fraudulent and not from Volkswagen Group Middle East. 
                            <br/><br/>
                            To explore potential job openings at Volkswagen Group Middle East, interested job applicants should visit our official career portal and review the career information provided. By adhering to these guidelines and being vigilant against fraudulent activities, you can ensure a safe and legitimate application process when seeking employment with Volkswagen Group Middle East.</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
