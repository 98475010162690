import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';

import careers from '../json/careers.json';

export default function Page(){

    const list = Object.values(careers);

	return (
		<div className="page">
            <div className="ui-banner">
                <div className="media">
                    <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Layers_darkgreen.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>Drive your career.</GroupuiHeadline>
                    <GroupuiText>Welcome to Volkswagen Group Middle East Careers page. Find out more below.</GroupuiText>
                </div>
            </div>

            <div className={`ui-content-teaser no-margin`}>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Working at Volkswagen Group Middle East.</GroupuiHeadline>
                </div>
            </div>

            {
            list.map((item,k) => (
                <div key={k} className={`ui-content-teaser no-gap-top`}>
                    <div className="ui-inner">
                        <div className="ui-columns">
                            <div className="ui-column">
                                <div className="media">
                                    <img src={`${process.env.PUBLIC_URL}${item.img.thumb}`} alt=""/>
                                </div>
                            </div>
                            <div className="ui-column">
                                <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{item.title}</GroupuiHeadline>
                                <GroupuiText>{item.lead}</GroupuiText>
                                <a href={item.cta[0].link} className="cta">{item.cta[0].text}</a>
                            </div>
                        </div>
                    </div>
                </div>
            ))
            }


        </div>
	);
}
