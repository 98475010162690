import { GroupuiHeadline, GroupuiText, GroupuiTable, GroupuiTableCell } from '@group-ui/group-ui-react';

export default function Page(){

	return (
		<div className="page">
            {/* banner */}
            <div className="ui-banner">
                <div className="media">
                    <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Layers_green_blue.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>Whistleblowers.</GroupuiHeadline>
                    <GroupuiText>Discover how our Whistleblower System works below.</GroupuiText>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/conduct.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Our Whistleblower System.</GroupuiHeadline>
                            <GroupuiText>Complying with statutory regulations and internal rules, and the principles laid down in our Code of Conduct and the Code of Conduct for Business Partners, has top priority at Volkswagen Group Middle East. The success of our company is based on Integrity and Compliance. To meet these standards, it is important to learn of potential employee or supplier misconduct and to put a stop to it. Therefore, we entrusted the Central Investigation Office to operate an independent, impartial and confidential Whistleblower System on our behalf.</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/compliance/whistleblower-chart.png`} alt=""/>
                            </div>
                            <br/><br/>
                            <GroupuiText>A key pillar of our Whistleblower System is the principle of procedural fairness. It also guarantees the greatest possible protection for whistleblowers, persons implicated and employees contributing to the investigation of reported misconduct. <br/><br/>
                            This also includes offering opportunities for anonymous reporting and communication. We assure not to perform any steps in order to identify anonymous whistleblowers, who do not misuse our Whistleblower System. Retaliation of whistleblowers and all persons who contribute to investigations at Volkswagen Group Middle East will not be tolerated. Persons implicated are presumed innocent until the violation is proven. Investigations will be conducted with the utmost confidentiality. The information will be processed in a fair, fast and protected process.</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>How do we process your report?</GroupuiHeadline>
                            <GroupuiText>
                                The qualified and experienced colleagues at the Investigation Office examine every report for potential misconduct by a Volkswagen Group employee thoroughly and follow it up systematically.  First, you will get a confirmation of  receipt. The Investigation Office then assesses your report. This includes gathering facts particularly from the whistleblower. Only if this initial evaluation shows grounds for suspicion of a violation an investigation by a dedicated Investigating Unit will be started. Afterwards, the results of the investigation will be assessed by the Investigation Office and appropriate measures will be recommended. Information about the status* and the outcome of the procedure will be given to you without undue delay.
                                <br/><br/>
                                Potential violations of the Code of Conduct for Business Partners by suppliers, including serious risks and violations of human rights and environment by direct and indirect suppliers, can also be reported to the Investigation Office -  as well as reports requiring otherwise immediate action. The Investigation Office will inform the responsible departments, who will process the issue accordingly. This particularly includes taking the necessary measures to minimize or end violations and/or risks.
                                <br/><br/>
                                More information on the respective procedural principles can be <a href="https://www.volkswagen-group.com/en/publications/more/rules-of-procedure-for-the-volkswagen-group-complaints-procedure-2007" className="text-link">found here.</a>
                                <br/><br/>
                                *The processing time varies depending on the subject of the procedure
                            </GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>What kind of report do you want to submit?</GroupuiHeadline>
                            <GroupuiText>
                            <b>A - A complaint about products or services to our customer service</b>
                            <br/>
                            For complaints or feedback about vehicles and services of Volkswagen Group Middle East or our business partners (e.g. car dealerships, workshops), please contact your respective country’s Customer Care:<br/><br/>
                            Volkswagen <a href="https://www.volkswagen-me.com/en/country-selector.html" className="text-link">https://www.volkswagen-me.com/en/country-selector.html</a><br/>
                            Audi <a href="https://www.audi-me.com/me/web/meen/tools/form/region-select.html" className="text-link">https://www.audi-me.com/me/web/meen/tools/form/region-select.html</a><br/>
                            Skoda <a href="https://www.skoda-me.com/contact/owners-support" className="text-link">https://www.skoda-me.com/contact/owners-support</a><br/><br/>
                            Please understand that we will not be able to forward your request or take any action for reasons of responsibility.
                            <br/><br/>
                            <b>B - A report of potential misconduct to our Whistleblower System</b>
                            <br/>
                            The Whistleblower System offers various channels for reporting potential misconduct by Volkswagen Group Middle East employees, violations of the Code of Conduct for Business Partners or serious risks and violations of human rights and environment in our Supply Chain. 
                            </GroupuiText>
                            <br/><br/>
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Online Reporting Channel</GroupuiHeadline>
                            <GroupuiText>
                                <b>E-Mail address:</b><br/>
                                The Investigation Office of Volkswagen Group Middle East can be reached via E-mail (in any language): <br/>
                                <a href="mailto:io@volkswagen.de" className="text-link">io@volkswagen.de</a><br/>
                                For customer complaints, please contact the channels listed under "A complaint about products or services to our customer service".
                                <br/><br/>
                                <b>Postal address / in person:</b><br/>
                                Central Investigation Office<br/>
                                Postal address: <br/>
                                Volkswagen AG, Central Investigation Office<br/>
                                Mailbox 1717<br/>
                                Berliner Ring 2<br/>
                                38436 Wolfsburg, Germany<br/>
                                <br/>In person:<br/>
                                Please make an appointment in advance by writing an e-mail to: <a href="mailto:io@volkswagen.de" className="text-link">io@volkswagen.de</a>.
                                <br/><br/>
                                <b>Ombudspersons of Volkswagen Group:</b><br/>
                                Volkswagen Group Middle East has appointed external lawyers to act as Ombudspersons. They advise on the Whistleblower System or ensure that reports from whistleblowers are forwarded anonymously to the Investigation Office if desired. 
                                <br/><br/>
                                If you want to get in contact with the Ombudsperson you can find their contact details here: <a href="http://www.ombudsmen-of-volkswagen.com/" target="_blank" rel="noreferrer" className="text-link">http://www.ombudsmen-of-volkswagen.com/</a>
                                <br/><br/>
                                <b>Online Platform:</b><br/>
                                You have the option of using a <a href="https://goto.speakup.report/volkswagen" className="text-link">web-based communication platform</a> to contact the Investigation Office in more than 65 languages. This system is confidential and technically secured and allows you to submit reports anonymously. <br/><br/>
                                You can access the online reporting channel through the following link: <a href="https://goto.speakup.report/volkswagen" className="text-link">https://goto.speakup.report/volkswagen</a><br/><br/>
                                You can find detailed instructions on how to make reports online, by app or by phone <a href={`${process.env.PUBLIC_URL}/assets/docs/speak-up.pdf?nocache=true`} className="text-link">here.</a>
                                <br/><br/>
                                <b>Voice Intake by phone:</b><br/>
                                You can leave us a voice message by phone 24 hours a day, 7 days a week.<br/>
                                After entering the phone number (specific to the country you are in), you will be prompted to enter the organization code. Then, you can submit your report as a voice recording. Only a written transcript will be received by the Investigation Office. Dial back in to listen to the reply or further questions by our team.<br/><br/>
                                1. Please call  (Qatar) / 800 0444 0408 (United Arab Emirates)<br/>
                                2. Have the organizational code 122203 ready<br/><br/>
                                ATTENTION: If you are outside of the Middle East, you can select a phone number <a href={`${process.env.PUBLIC_URL}/assets/docs/speak-up-phone.pdf?nocache=true`} className="text-link">from this list</a> for the country or region you are in.
                                After entering the telephone number and the organizational code, a report can be given as a voice recording via Voice Intake. Only a written transcript will be received by the Investigation Office. Dial back in to listen to the reply or further questions by our team. 
                                <br/><br/>
                                <b>SpeakUp App:</b><br/>
                                You can also download the Speak Up App “SpeakUp - Listen for a change “ by People Intouch (personal devices only) to access the secure reporting system. Through this app, you can submit your report in writing or leave a voice message (also anonymously). You will need the organization code 122203. Log back in to see our team's reply or answer further questions. To download the app, you can also scan the QR code:
                                <br/><br/>
                                <div style={{display:'flex',gap:'16px',flexWrap:'wrap'}}>
                                <img src={`${process.env.PUBLIC_URL}/assets/img/code2.jpg`} alt=""/>
                                <img src={`${process.env.PUBLIC_URL}/assets/img/code.jpg`} alt=""/>
                                </div>
                                <br/><br/>
                                <b>Do you have further questions?</b>
                                <br/><br/>
                                Questions or suggestions for improvement concerning the Whistleblower System can also be addressed to the Investigation Office. <br/>
                                Furthermore, our local Compliance Officer/responsible function can also be adressed in all matters of the Whistleblower System via <a href="mailto:compliance.vwgme.r.doha@vwgme.com" className="text-link">compliance.vwgme.r.doha@vwgme.com</a>.
                                <br/><br/>
                                <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}> Making a report to our Whistleblower System</GroupuiHeadline>
                                <GroupuiText>The Whistleblower System offers various channels to report potential employee misconduct that allow a swift review and reaction by our company if necessary.</GroupuiText>
                                <a href={`${process.env.PUBLIC_URL}/assets/docs/whistleblowers-2024.pdf`} className="cta">Download to submit report</a>
                            </GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
