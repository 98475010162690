import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';

export default function Page(){

	return (
		<div className="page">
            {/* banner */}
            <div className="ui-banner">
                <div className="media">
                    <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Layers_green_blue.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>Group <span className="ui-neon-text">Strategy.</span></GroupuiHeadline>
                    <GroupuiText>Shaping mobility for generations. </GroupuiText>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column" style={{textAlign:'center'}}>
                            <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>Strategy</GroupuiHeadline>
                            <GroupuiText>As technology advances, the automotive industry is rapidly forging ahead with its transformation toward e-mobility and digitalization. We therefore expect the market for electric vehicles to grow strongly in the next few years, meaning that the cost-efficient and sustainable production of battery systems and the expansion of the charging infrastructure will be crucial to success.</GroupuiText>
                            <a href="https://www.volkswagen-group.com/en/strategy-15955" target="_blank" rel="noreferrer" className="cta">Discover more</a>
                            <br/><br/><br/>
                            <div className="media">
                                <video src={`${process.env.PUBLIC_URL}/assets/video/strategy.mp4`} controls autoPlay muted playsInline loop/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Evolving Mobility.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Our vision for 2030.</GroupuiHeadline>
                            <GroupuiText>The shift to connected, intelligent and eventually self-driving vehicles will, however, bring more wide-reaching changes for the automotive industry. Autonomous driving will change the customer's mobility experience forever and lay the ground for new business models. Sources of revenue will gradually shift and will expand beyond the core product of the automobile. Increasing software development capabilities in order to excite customers with constantly improving digital functionality is the prerequisite for this. <br/><br/>As we transition from automotive manufacturer to mobility group, we are resetting our priorities with NEW AUTO and positioning ourselves for the future. We are keeping our aim of being a world-leading provider of sustainable mobility firmly in our sights and making the Group more focused, efficient, innovative, customer-oriented and sustainable, as well as systematically gearing it toward profitable growth.</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>About us.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/about.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Discover the heart of innovation.</GroupuiHeadline>
                            <GroupuiText>Step into Volkswagen Group Middle East. Your premier connection to our regional operations from Doha and Dubai awaits. Click to learn more about your gateway to our network of Dealers and Partners.</GroupuiText>
                            <a href="/group/about" className="cta">Learn more</a>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
