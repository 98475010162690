import { useState, useEffect } from 'react';

export default function useBreaks(){

    let [mobi,setMobi] = useState(false),
        [tablet,setTablet] = useState(false),
        [mid,setMid] = useState(false),
        [desktop,setDesktop] = useState(false),
        [max,setMax] = useState(false);

    useEffect(() => {
        let resize = () => {
            setMobi(window.innerWidth < 540 ? true:false);
            setTablet(window.innerWidth < 768 ? true:false);
            setMid(window.innerWidth < 1024 ? true:false);
            setDesktop(window.innerWidth < 1440 ? true:false);
            setMax(window.innerWidth < 1920 ? true:false);
        }
        resize();
        window.addEventListener('resize',resize);
        return () => window.removeEventListener('resize',resize);
    }, [])

    return {mobi:mobi,tablet:tablet,mid:mid,desktop:desktop,max:max}
}