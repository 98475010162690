import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';

export default function Page(){

	return (
		<div className="page">
            <div className="ui-banner">
                <div className="media">
                    <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Mountains_green_blue.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>Volkswagen Group <br/> <span className="ui-neon-text">Middle East.</span></GroupuiHeadline>
                    <GroupuiText>Welcome to Volkswagen Group Middle East. Step in and discover!</GroupuiText>
                </div>
            </div>

            <div className={`ui-content-teaser no-margin`}>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Explore our Group operations in the Middle East.</GroupuiHeadline>
                </div>
            </div>
            
            <div className={`ui-content-teaser no-gap-top`}>
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/about.jpg?123`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>About us</GroupuiHeadline>
                            <GroupuiText>Discover who we are and where we are located within the region.</GroupuiText>
                            <a href="/group/about" className="cta">Find out more</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`ui-content-teaser no-gap-top`}>
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/team.jpg?123`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Our team</GroupuiHeadline>
                            <GroupuiText>Meet the team at Volkswagen Group Middle East.</GroupuiText>
                            <a href="/group/team" className="cta">Find out more</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`ui-content-teaser no-gap-top`}>
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/ci/1.jpg?123`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Group strategy</GroupuiHeadline>
                            <GroupuiText>Shaping the future of mobility with Volkswagen Group.</GroupuiText>
                            <a href="/group/strategy" className="cta">Find out more</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`ui-content-teaser no-gap-top`}>
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/fleet-banner.jpg?123`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Group Fleet Solutions</GroupuiHeadline>
                            <GroupuiText>Find out more information on our Fleet services and send us a enquiry.</GroupuiText>
                            <a href="/group/fleet" className="cta">Find out more</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`ui-content-teaser no-gap-top`}>
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/ci/5.jpg?123`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Integrity, Compliance and Risk</GroupuiHeadline>
                            <GroupuiText>Volkswagen Group Middle East takes a proactive approach to compliance with laws, regulations, internal requirements. Find out more.</GroupuiText>
                            <a href="/group/integrity" className="cta">Find out more</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`ui-content-teaser no-gap-top`}>
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/conduct.jpg?123`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Code of Conduct</GroupuiHeadline>
                            <GroupuiText>Discover our principles and compliance topics.</GroupuiText>
                            <a href="/group/conduct" className="cta">Find out more</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`ui-content-teaser no-gap-top`}>
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/ci/2.jpg?123`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Whistleblower System</GroupuiHeadline>
                            <GroupuiText>Discover how our Whistleblower System works.</GroupuiText>
                            <a href="/group/whistleblower" className="cta">Find out more</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`ui-content-teaser no-gap-top`}>
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/ci/3.jpg?123`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Business & Human Rights</GroupuiHeadline>
                            <GroupuiText>Discover how we're protecting the freedom and dignity throughout our business.</GroupuiText>
                            <a href="/group/human-rights" className="cta">Find out more</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
	);
}
