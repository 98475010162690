import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';

import useBreaks from '../hooks/useBreaks';
// import brands from '../json/brands.json';

export default function Component(){

    const mq = useBreaks();

	return (
        <div className="ui-content-teaser ui-brand-grid">
            <div className="ui-inner">
                <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Our brands.</GroupuiHeadline>
                <div className="ui-columns">
                    <div className="ui-column">
                        <div className="item">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/brands/grid-vw.jpg?123`} alt="brand"/>
                            </div>
                            <div className="info">
                                <div className="inner">
                                    <div>
                                        {/* <img src={`${process.env.PUBLIC_URL}/assets/img/logo/vw.png`} alt=""/> */}
                                        <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Volkswagen</GroupuiHeadline>
                                        <GroupuiText>Das Auto.</GroupuiText>
                                    </div>
                                    <div>
                                        <a href="/brands?scroll=vw" className="cta">Explore brand</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ui-column">
                        <div className="item">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/brands/grid-audi.jpg?123`} alt="brand"/>
                            </div>
                            <div className="info">
                                <div className="inner">
                                    <div className="audi">
                                        {/* <img src={`${process.env.PUBLIC_URL}/assets/img/logo/audi.png`} alt=""/> */}
                                        <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Audi</GroupuiHeadline>
                                        <GroupuiText>Future is an Attitude.</GroupuiText>
                                    </div>
                                    <div>
                                        <a href="/brands?scroll=audi" className="cta">Explore brand</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ui-column">
                        <div className="item">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/brands/grid-skoda.jpg?123`} alt="brand"/>
                            </div>
                            <div className="info">
                                <div className="inner">
                                    <div>
                                        {/* <img src={`${process.env.PUBLIC_URL}/assets/img/logo/skoda.png`} alt=""/> */}
                                        <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Škoda</GroupuiHeadline>
                                        <GroupuiText>Let's Explore.</GroupuiText>
                                    </div>
                                    <div>
                                        <a href="/brands?scroll=skoda" className="cta">Explore brand</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ui-column">
                        <div className="item">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/brands/grid-bentley.jpg?123`} alt="brand"/>
                            </div>
                            <div className="info">
                                <div className="inner">
                                    <div>
                                        {/* <img src={`${process.env.PUBLIC_URL}/assets/img/logo/bentley.png`} alt=""/> */}
                                        <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Bentley</GroupuiHeadline>
                                        <GroupuiText>Extraordinary Journeys.</GroupuiText>
                                    </div>
                                    <div>
                                        <a href="/brands?scroll=bentley" className="cta">Explore brand</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ui-column">
                        <div className="item">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/brands/grid-lambo.jpg?123`} alt="brand"/>
                            </div>
                            <div className="info">
                                <div className="inner">
                                    <div>
                                        {/* <img src={`${process.env.PUBLIC_URL}/assets/img/logo/lamborghini.png`} alt=""/> */}
                                        <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Lamborghini</GroupuiHeadline>
                                        <GroupuiText>Driving Humans Beyond.</GroupuiText>
                                    </div>
                                    <div>
                                        <a href="/brands?scroll=lamborghini" className="cta">Explore brand</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	);
}
