import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';

export default function Page(){

	return (
		<div className="page">
            {/* banner */}
            <div className="ui-banner">
                <div className="media">
                    <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Mountains_darkgreen.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>Code of<span className="ui-neon-text"> Conduct.</span></GroupuiHeadline>
                    <GroupuiText>Discover our principles and compliance topics below.</GroupuiText>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Preface.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/conduct.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Our Code of Conduct.</GroupuiHeadline>
                            <GroupuiText>In the long term, a company can only be successful if it acts with integrity, complies with statutory provisions worldwide, and stands by its voluntary undertakings and ethical principles, even when this is the harder choice.<br/><br/>
                            <b>Download our Code of Conduct documents.</b></GroupuiText>
                            <div className="btns">
                                <a href="https://www.volkswagen-group.com/en/publications/more/the-code-of-conduct-of-the-volkswagen-group-1882" target="_blank" rel="noreferrer" className="cta">Download</a>
                                <a href="https://www.volkswagen-group.com/en/publications/more/code-of-conduct-for-business-partner-1885" target="_blank" rel="noreferrer" className="cta">Download for Business Partners</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Integrity Management.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>“Compliance must be second nature.”</GroupuiHeadline>
                            <GroupuiText>Volkswagen Group Middle East is committed to this principle. Compliance must be second nature. The VW group Code of Conduct outlines the ethical principles and behaviors which we expect from all of our employees in all of our business dealings.
                            <br/><br/>
                            These high standards are also expected to be followed by our business partners. Business Partners are expected to act responsibly and to agree to comply with the requirements defined in the Code of Conduct for Business Partners.
                            <br/><br/>
                            Comprehensive training on the Code of Conduct for both Employees and Business Partners is mandatory and provided on a regular basis.
                            </GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
        
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Whistleblower system.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/gradient3.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Integrity at Its Core.</GroupuiHeadline>
                            <GroupuiText>We are committed to fairness and protection. Discover how Volkswagen Group Middle East upholds the highest standards for whistleblowers and ensures a safe, anonymous platform for reporting misconduct. Click to learn about our dedication to procedural fairness and the safeguards in place for all parties involved.</GroupuiText>
                            <a href="/group/whistleblower" className="cta">Learn more</a>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
