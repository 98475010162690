import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';

import news from '../json/news.json';

export default function Page(){

	return (
		<div className="page">
            <div className="ui-banner">
                <div className="media">
                    <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Wave_blue.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}><span className="ui-neon-text">Newsroom.</span></GroupuiHeadline>
                    <GroupuiText>Stay informed with the latest news.</GroupuiText>
                </div>
            </div>

            <div className={`ui-content-teaser no-margin`}>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Explore the latest news in the region and across the globe. </GroupuiHeadline>
                </div>
            </div>

            {
            news.map((item,k) => (
                <div key={k} className={`ui-content-teaser no-gap-top`}>
                    <div className="ui-inner">
                        <div className="ui-columns">
                            <div className="ui-column">
                                <div className="media five-three">
                                    <img src={`${process.env.PUBLIC_URL}${item.img.thumb}`} alt=""/>
                                </div>
                            </div>
                            <div className="ui-column">
                                <div className="ui-chip">{item.brand} News</div>
                                <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{item.title}</GroupuiHeadline>
                                <GroupuiText>{item.lead}</GroupuiText>
                                <div className="btns">
                                    <a href={item.cta[0].link} target="_blank" rel="noreferrer" className="cta">Read article</a>
                                    <a href={item.cta[1].link} target="_blank" rel="noreferrer" className="cta">All articles</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))
            }

        </div>
	);
}
