import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';

export default function Page(){

	return (
		<div className="page">
            {/* banner */}
            <div className="ui-banner">
                <div className="media">
                    <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Layers_darkgreen.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>Integrity, <span className="ui-neon-text">Compliance</span> <br/>& Risk.</GroupuiHeadline>
                    <GroupuiText>Find out more information below.</GroupuiText>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Preface.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/gradient2.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Our approach.</GroupuiHeadline>
                            <GroupuiText>Volkswagen Group Middle East takes a proactive approach to compliance with laws, regulations, internal requirements, and its social & ethical commitments. We aim to increase employee awareness and knowledge to prevent potential rule breaches before they happen.</GroupuiText>
                            <a href="/group/conduct" className="cta">Code of conduct</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Compliance Management.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Volkswagen Group Middle East takes a proactive approach to compliance with laws, regulations, internal requirements, and its social & ethical commitments.</GroupuiHeadline>
                            <GroupuiText>We aim to increase employee awareness and knowledge to prevent potential rule breaches before they happen. Compliance at Volkswagen Group Middle East is implemented through mitigating control activities with an overall aim to ensure that all employees act in accordance with internal regulations and all legal requirements. These processes are closely integrated to form a holistic compliance management system, which also include Product and Environmental compliance.
                            <br/><br/>
                            Our compliance management system enables us to identify compliance risks such as corruption, money laundering, human rights abuses, environmental compliance and product conformity and implement effective mitigating controls such as a robust risk management framework to effectively identify and manage compliance risks, corporate policies and procedures, a risk based compliance training curriculum, due diligence processes on all business partners and tone from the top including regular communication activities.
                            <br/><br/>
                            A Group wide code of Conduct forms the basis for all decisions taken in the company and offers guidance for ethical conduct. Other publications of the Volkswagen Group offer further detailed information and advice.</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Risk Management.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Risks are defined as internal and external events that may have a negative impact on the achievement of our business objectives, the ability to comply with regulatory requirements and the adherence to, or viability of, company processes.</GroupuiHeadline>
                            <GroupuiText>
                                
                                At Volkswagen Group Middle East, the management and control of risks is supported by a comprehensive Risk Management System. The Risk Management System (RMS) covers the principles, procedures and measures to identify, assess, manage, control, communicate and monitor risks.
                                <br/><br/>
                                Risks are steered by a committee which regularly meets to review the company wide risks and agree adequate mitigation.
                            </GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Integrity Management.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>As the Board of Management of Volkswagen Group Middle East, we firmly believe that Integrity and Compliance are fundamental building blocks of sustainable success.</GroupuiHeadline>
                            <GroupuiText>
                            Our Vision is to play a strategic role in connecting the Volkswagen Group with our partners in the region. It is our aim to constantly innovate to change and improve existing processes. We strive for excellence on the basis of the following commitments:
                            <br/><br/>
                           <b>WE:</b> We base our Actions not only on the law, but also on clear values and ethical principles.
                            OUR CUSTOMER: We do our utmost to provide our customers with safe, efficient and sustainable mobility.<br/><br/>
                            <b>OUR PRODUCTS:</b> We use our innovative strength to reduce the environmental impact of our processes, products and services throughout their entire lifecycle.<br/><br/>
                            <b>OUR PARTNERS:</b> We also require our suppliers, service providers and sales partners to uphold our compliance and sustainability standards.<br/><br/>
                            <b>OUR COMPANY:</b> With our commitment to education, culture and sport, we contribute to social cohesion.
                            <br/><br/>
                            Our commitments underscore that, in addition to ensuring our economic success, our goal is to make our company better in every respect. They are binding and it is a compulsory ethical framework guiding the work of the Board of Management. These obligations help us to make the right decisions when conflicting goals are involved and unambiguously express that not everything that is legal is also ethical.
                            </GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Whistleblower system.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/gradient3.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Integrity at Its Core.</GroupuiHeadline>
                            <GroupuiText>We are committed to fairness and protection. Discover how Volkswagen Group Middle East upholds the highest standards for whistleblowers and ensures a safe, anonymous platform for reporting misconduct. Click to learn about our dedication to procedural fairness and the safeguards in place for all parties involved.</GroupuiText>
                            <a href="/group/whistleblower" className="cta">Learn more</a>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
