import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';
import useBreaks from '../../hooks/useBreaks';

const team = [
    {
        top:{
            text:"Chief Executive Officer"
        },
        img:{
            thumb:"/assets/img/team/benoit.jpg"
        },
        title:'Benoit Tiers',
        lead:'Benoit is our Chief Executive Officer and Chairman of the Board of Management of our organizations in Doha and Dubai. He has an extensive career in the automotive industry, having worked for several major car manufacturers in the past. He joined the Volkswagen Group in 1999 and has acquired a solid international experience in France, Taiwan, India, Germany and in the Middle East. Holding both an MBA and a degree in Computer Sciences, Benoit is a very strategic and visionary leader, but always very capable to steer daily operations. The senior management and the brand directors are reporting to him in our structure. When asking him about management and values, Benoit will always refer to people. “There is nothing more valuable in our organization than the people composing it. Creating a professional and motivating work environment, as well as developing individuals within the company are major keys to future success.”'
    },
    {
        top:{
            text:"Chief Financial Officer"
        },
        img:{
            thumb:"/assets/img/team/jeff.jpg"
        },
        title:'Jeffrey Dehate',
        lead:'Jeff is our Chief Financial Officer. Jeff brings to Volkswagen Group Middle East a long record of success and international experience in the Volkswagen Group. Joining VW Group in 1993, he has led many teams and projects in Europe, South America, India, Southeast Asia, and now in the Middle East Region. His unique management style ensures compliant and responsible behavior, while at the same time focusing on achieving the operational and financial objectives of the organization. He is a true asset to the organization, fostering a positive work environment and continued success for the organization in the future.'
    },
    {
        top:{
            text:"Group HR Director"
        },
        img:{
            thumb:"/assets/img/team/rachel.jpg"
        },
        title:'Rachel Madeleine',
        lead:'Rachel is our Human Resources Director.  With a tenure dating back to 2005, Rachel has been a cornerstone of our organization\'s success. Her commitment to fostering a positive workplace culture and ensuring the well-being of our diverse team has made her an integral part of our company\'s journey. Rachel brings a wealth of experience and expertise to her role, overseeing recruitment, talent development, and employee relations with a keen understanding of the human element in the corporate landscape. Beyond her professional acumen, Rachel is known for her approachability and dedication to creating an inclusive and supportive work environment. As a driving force behind our team\'s growth and cohesion, Rachel exemplifies the values that define our company.'
    },
    {
        top:{
            text:"Audi, Managing Director"
        },
        img:{
            thumb:"/assets/img/team/rene.jpg"
        },
        title:'René Koneberg',
        lead:'René is our Managing Director for Audi in the Middle East. René brings his global experience from Audi and Volkswagen in Korea, Hong Kong, China, and several European markets. With this he aims to support business development in the region, particularly in areas focused to electrification and customer-centric experiences. In this role, he’s dedicated to drive innovation, sustainability, and excellence in the fast-growing automotive landscape of the Middle East as well as shaping Audi\'s future in premium mobility.'
    },
    {
        top:{
            text:"VW, Managing Director"
        },
        img:{
            thumb:"/assets/img/team/matthias.jpg"
        },
        title:'Matthias Ziegler',
        lead:'Matthias is our Managing Director for Volkswagen in the Middle East. Matthias has been with the Volkswagen Group since 2006. Having started his career at the Volkswagen Headquarters as an International Management Trainee he has since gone on to hold many senior sales positions over the course of his career in Germany and China. Matthias has been the Managing Director of Volkswagen Middle East since August 2023 and brings to the region almost 20 years of expertise, eye for innovation and a strong passion for all things automotive.'
    },
    {
        top:{
            text:"Škoda, Managing Director"
        },
        img:{
            thumb:"/assets/img/team/lukas.jpg"
        },
        title:'Lukáš Honzák',
        lead:'Lukáš is our Managing Director for Škoda in the Middle East. With over 15 years of leadership experience in Škoda sales, marketing and network development across various regions, Lukáš brings a very strong Škoda DNA to the Middle East. His profound understanding of Škoda’s core values as well as latest trends in automotive make him the perfect leader to establish solid foundations for the successful future of the Škoda brand in the Middle East. Lukáš’s mission revolves mainly around enhancing the brand’s visibility, expanding Škoda sales and service network into new regional territory and establishing clear customer centric business processes. He places a strong emphasis on a customer-first approach and under his guidance, Škoda brand aims to answer specific needs of Middle Eastern market with its vast portfolio of products and customer focused services. His leadership philosophy is rooted in dedication, passion, and wholeheartedness, fostering a positive environment that inspires and motivates his team.'
    },
    {
        top:{
            text:"Group After-sales Director"
        },
        img:{
            thumb:"/assets/img/team/egemen.jpg"
        },
        title:'Egemen Adiyaman',
        lead:'Egemen is our Group After Sales Director in Volkswagen Group Middle East. In her current capacity, she oversees Group After Sales Business (parts logistics, regional warehouse, pricing, Bodyshop business and new business development), Training Academy (Sales and After Sales Training), Field Operations (Regional Business Steering, Warranty and Technical). Before joining Group After Sales in June 2019, Egemen was responsible for Audi After Sales. Prior to that, she worked for Japanese and American OEM’s regional head offices. Throughout her 22-year automotive career, she has worked in R&D, Network Development and in After Sales, lived-worked in 5 different countries, had the opportunity to work with 45+ National Sales Companies across Europe and Middle East. Egemen is enthusiastic about automotive engineering and her ultimate goal is to keep VW Group customers satisfied with our services.'
    }
]

export default function Page(){
    const mq = useBreaks();
	return (
		<div className="page">

            <div className="ui-banner">
                <div className="media">
                    <img src={`${process.env.PUBLIC_URL}/assets/img/team/${mq.tablet ? 'mobi':'banner'}.jpg`} alt=""/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}><span className="ui-neon-text">Leadership Team.</span></GroupuiHeadline>
                    <GroupuiText>Meet the leadership team at Volkswagen Group Middle East.</GroupuiText>
                </div>
            </div>

            <div className={`ui-content-teaser no-margin no-gap-bottom`}>
                <div className="ui-inner">
                    {/* <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Meet our Senior Leadership Team at Volkswagen Group Middle East</GroupuiHeadline> */}
                </div>
            </div>

            {
            team.map((item,k) => (
                <div key={k} className={`ui-content-teaser no-gap-top`}>
                    <div className="ui-inner">
                        <div className="ui-columns">
                            <div className="ui-column">
                                <div className="media boxed align-top">
                                    <img src={`${process.env.PUBLIC_URL}${item.img.thumb}`} alt=""/>
                                </div>
                            </div>
                            <div className="ui-column">
                                <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>{item.title}</GroupuiHeadline>
                                <GroupuiHeadline heading="h6" fontVariant="TheGroupHEAD-Bold" marketing={false}>{item.top.text}</GroupuiHeadline>
                                <GroupuiText>{item.lead}</GroupuiText>
                            </div>
                        </div>
                    </div>
                </div>
            ))
            }
		</div>
	);
}
