import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Error from "../pages/Error";
import App from "../App";
import Home from "../pages/Home";
import Group from "../pages/Group";
import Brands from "../pages/Brands";
import Careers from "../pages/Careers";
import News from "../pages/News";

import Contact from "../pages/Contact";
import About from "../pages/group/About";
import Team from "../pages/group/Team";
import Strategy from "../pages/group/Strategy";
import Integrity from "../pages/group/Integrity";
import Conduct from "../pages/group/Conduct";
import Whistleblower from "../pages/group/Whistleblower";
import HumanRights from "../pages/group/HumanRights";
import Fleet from "../pages/group/Fleet";

import WorkingWithUs from '../pages/WorkingWithUs';

import Template from "../pages/Template";

// routes
const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <Error/>,
        children: [
            { 
                index: true, 
                element: <Home /> 
            },
            {
                path: "group",
                element: <Group/>
            },
            {
                path: "group/about",
                element:<About/>
            },
            {
                path: "group/team",
                element:<Team/>
            },
            {
                path: "group/strategy",
                element:<Strategy/>
            },
            {
                path: "group/integrity",
                element:<Integrity/>
            },
            {
                path: "group/conduct",
                element:<Conduct/>
            },
            {
                path: "group/whistleblower",
                element:<Whistleblower/>
            },
            {
                path: "group/human-rights",
                element:<HumanRights/>
            },
            {
                path: "group/fleet",
                element:<Fleet/>
            },
            {
                path: "brands",
                element: <Brands/>
            },
            {
                path: "careers",
                element: <Careers/>
            },
            {
                path: "careers/working-with-us",
                element:<WorkingWithUs/>
            },
            {
                path: "news",
                element: <News/>
            },
            {
                path: "contact",
                element: <Contact/>
            },
            {
                path: "template",
                element: <Template/>
            }
        ]
    }
]);

function Provider(){
	return (
		<RouterProvider router={router}/>
	);
}

export default Provider;
