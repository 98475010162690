import useBreaks from '../hooks/useBreaks';

import { GroupuiHeadline, GroupuiText, GroupuiButton } from '@group-ui/group-ui-react';

export default function Page(){
    const mq = useBreaks();

	return (
		<div className="page">
            {/* banner */}
            <div className="ui-banner">
                <div className="media">
                    {/* <img src={`${process.env.PUBLIC_URL}/assets/img/banners/bridge.jpg`} alt=""/> */}
                    <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Mountains_green_blue.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>Headline <br/><span className="ui-neon-text">With highlight</span></GroupuiHeadline>
                    <GroupuiText>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</GroupuiText>
                    <button className="cta">Discover more</button>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Mechatronic</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`https://uploads.vw-mms.de/system/production/images/cws/076/845/images/346ba37bda46e0c6331222a347e38584a78ed236/B2023CW00397_web_804.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Mechatronics – Backbone And Scalable Systems Platform</GroupuiHeadline>
                            <GroupuiText>A future-oriented mechatronics platform will form the backbone for innovations, technology and lasting competitiveness at Volkswagen.With the Scalable Systems Platform (SSP), we are creating the next gene­ration of an all-electric, fully digital and highly scalable mechatronics platform based on a standardized software architecture. With this standardized platform, which can be scaled from the smallest vehicles all the way up to the premium segment, the Volkswagen Group aims to rapidly and efficiently provide.</GroupuiText>
                            <button className="cta">Discover more</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Mechatronic</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Mechatronics – Backbone And Scalable Systems Platform</GroupuiHeadline>
                            <GroupuiText>A future-oriented mechatronics platform will form the backbone for innovations, technology and lasting competitiveness at Volkswagen.With the Scalable Systems Platform (SSP), we are creating the next gene­ration of an all-electric, fully digital and highly scalable mechatronics platform based on a standardized software architecture. With this standardized platform, which can be scaled from the smallest vehicles all the way up to the premium segment, the Volkswagen Group aims to rapidly and efficiently provide.</GroupuiText>
                        </div>
                        <div className="ui-column">
                            <GroupuiText>A future-oriented mechatronics platform will form the backbone for innovations, technology and lasting competitiveness at Volkswagen.With the Scalable Systems Platform (SSP), we are creating the next gene­ration of an all-electric, fully digital and highly scalable mechatronics platform based on a standardized software architecture. With this standardized platform, which can be scaled from the smallest vehicles all the way up to the premium segment, the Volkswagen Group aims to rapidly and efficiently provide.</GroupuiText>
                            <button className="cta">Discover more</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* single img */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Mechatronic</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`https://uploads.vw-mms.de/system/production/images/cws/076/845/images/346ba37bda46e0c6331222a347e38584a78ed236/B2023CW00397_web_804.jpg`} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* video */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <div className="media">
                        <video src={`https://uploads.vw-mms.de/system/production/videos/cws/012/178/video_file_de/5450475197eae32571a3d65a6513a8e8b51bbd14/V2023CW0079_720p.webm?1694079323`} autoPlay muted loop playsInline/>
                    </div>
                </div>
            </div>
            {/* single teaser */}
            <div className="ui-content-teaser no-gap-top">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Mechatronics – Backbone And Scalable Systems Platform</GroupuiHeadline>
                            <GroupuiText>A future-oriented mechatronics platform will form the backbone for innovations, technology and lasting competitiveness at Volkswagen.With the Scalable Systems Platform (SSP), we are creating the next gene­ration of an all-electric, fully digital and highly scalable mechatronics platform based on a standardized software architecture. With this standardized platform, which can be scaled from the smallest vehicles all the way up to the premium segment, the Volkswagen Group aims to rapidly and efficiently provide.</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
