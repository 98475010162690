import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';

export default function Page(){

	return (
		<div className="page">
            {/* banner */}
            <div className="ui-banner">
                <div className="media">
                    <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Mountains_green_blue.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>Volkswagen Group <br/><span className="ui-neon-text">Middle East.</span></GroupuiHeadline>
                    <GroupuiText>Find out more about who we are and where we are based.</GroupuiText>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/about.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>About us.</GroupuiHeadline>
                            <GroupuiText>Welcome to Volkswagen Group Middle East, the Regional office for the Group operations in the Middle East. From our locations in Doha and Dubai, we are the first point of contact for our Dealers and Partners in the region. </GroupuiText>
                            <a href="/group/team" className="cta">Discover Team</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Driving Innovation.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiText>Volkswagen Group is one of the world’s leading automobile manufacturers and the largest car maker in Europe. <br/><br/>The Volkswagen Group comprises brands from several European countries. For Passenger vehicles: Audi, Volkswagen, Škoda, Bentley, Porsche, SEAT, Ducati, Lamborghini and for Commercial vehicles Volkswagen Trucks & Buses, Scania, Navistar and MAN. Each brand has its own character and operates as an independent entity on the market. The product spectrum ranges from motorcycles to low-consumption small cars and luxury vehicles. In the commercial vehicle sector, the products include ranges from pick-ups, buses and heavy trucks. </GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Our Journey in the Middle East.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Bold" marketing={false}>Volkswagen Group Middle East is the backbone for driving the Automotive business for the Group and is currently responsible for 12 markets in the region.</GroupuiHeadline>
                            <GroupuiText>Volkswagen Group Middle East is a wholly owned subsidiary of Volkswagen Finance Luxemburg (VFL) and was established in 2005 under the name of Audi Volkswagen Middle East. As of 2024, the company has changed its name to Volkswagen Group Middle East. The Group confirmed its commitment to its customers and partners in the region with the opening of the Regional Office based in Dubai overseeing 12 markets namely the UAE, Oman, Saudi Arabia, Kuwait, Bahrain, Qatar, Jordan, Lebanon, Iraq, Yemen and Syria. This initiative has allowed the brands to introduce their heritage, engineering strengths, technologies and a wider variety of high quality Audi and Volkswagen models to the region. Today, Volkswagen Group Middle East houses operations of some of the most esteemed and distinguished brands from the Group namely Audi, Volkswagen, Škoda, Bentley and Lamborghini.</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser seamless center spaced">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media eight-five">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/journey/1.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h4" fontVariant="TheGroupHEAD-Bold" marketing={false}>As of 2022, Volkswagen Group Middle East has its headquarters in Doha (Qatar), with functions such as Group management, HR, Finance and IT operating from this office.</GroupuiHeadline>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser seamless center spaced">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h4" fontVariant="TheGroupHEAD-Bold" marketing={false}>Our Dubai office houses all our brand operations – Sales, After sales, Marketing, Dealer Development and much more.</GroupuiHeadline>
                        </div>
                        <div className="ui-column">
                            <div className="media eight-five">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/journey/2.jpg`} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser seamless center spaced">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media eight-five">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/journey/3.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h4" fontVariant="TheGroupHEAD-Bold" marketing={false}>Through our training academy, we annually train approximately 1,500 dealer staff either online or through face-to-face trainings.</GroupuiHeadline>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser seamless center spaced">
                <div className="ui-inner">
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h4" fontVariant="TheGroupHEAD-Bold" marketing={false}>We run our parts operations in Jebel Ali Freezone where we store workshop tools, genuine spare parts, vehicles accessories and lifestyle brand merchandise.</GroupuiHeadline>
                        </div>
                        <div className="ui-column">
                            <div className="media eight-five">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/journey/4.jpg`} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
