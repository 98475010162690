import LatestNews from '../components/LatestNews';
import BrandGrid from '../components/BrandGrid';

import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';

export default function Page(){
	return (
		<div className="page">
            <div className="ui-banner">
                <div className="media">
                    <video src={`${process.env.PUBLIC_URL}/assets/video/home.mp4`} autoPlay muted loop playsInline/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>Powering Innovation.</GroupuiHeadline>
                    <GroupuiText>Welcome to Volkswagen Group Middle East.</GroupuiText>
                    <a href="/group" className="cta">Discover more</a>
                </div>
            </div>
            <section id="start">
                <LatestNews/>
                <BrandGrid/>
                <div className="ui-banner">
                    <div className="media flip">
                        <img src={`${process.env.PUBLIC_URL}/assets/img/fleet-banner.jpg`} alt=""/>
                    </div>
                    <div className="ui-inner">
                        <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>Volkswagen Group <br/><span className="ui-neon-text">Fleet Solutions.</span></GroupuiHeadline>
                        <GroupuiText>Discover the right solution to suit your needs.</GroupuiText>
                        <a href="/group/fleet" className="cta">Discover more</a>
                    </div>
                </div>
                <div className="ui-content-teaser">
                    <div className="ui-inner">
                        <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Quick links.</GroupuiHeadline>
                        <div className="ui-columns">
                            <div className="ui-column">
                                <div className="media">
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/grid-abstract1.jpg`} alt=""/>
                                    <a href="/careers" className="overlay"><GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={false}>Careers</GroupuiHeadline></a>
                                </div>
                            </div>
                            <div className="ui-column">
                                <div className="media">
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/grid-abstract2.jpg`} alt=""/>
                                    <a href="/contact" className="overlay"><GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={false}>Contact</GroupuiHeadline> us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
		</div>
	);
}
