import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';

export default function Page(){

	return (
		<div className="page">
            {/* banner */}
            <div className="ui-banner">
                <div className="media">
                    <img src={`${process.env.PUBLIC_URL}/assets/img/group/banner/together.jpg`} alt=""/>
                </div>
                <div className="ui-inner">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>Business & <br/><span className="ui-neon-text">Human Rights.</span></GroupuiHeadline>
                    <GroupuiText>Discover how we're protecting the freedom and dignity throughout our business.</GroupuiText>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Human rights.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <div className="media">
                                <img src={`${process.env.PUBLIC_URL}/assets/img/careers/lead.jpg`} alt=""/>
                            </div>
                        </div>
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>Our commitments.</GroupuiHeadline>
                            <GroupuiText>Volkswagen Group Middle East is committed to fulfilling the social responsibilities that accompany its status as a company with global supply-chains and reach. We are committed to protecting the freedom and dignity of individuals throughout all stages of our business, as well as the observance of fundamental freedoms regardless of gender, nationality, sexuality, origin, ethnic group, religion, or any other characteristic. Volkswagen Group Middle East stands firm in defending these unalienable rights.</GroupuiText>
                        </div>
                    </div>
                    <br/>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiText>As part of this, and alongside the wider Volkswagen Group, we confirm our commitment to major international agreements and declarations, in particular the International Bill of Human Rights and the core labour standards of the international labour organization (ILO). Our entrepreneurial activities follow the UN Guiding Principles on Business and Human Rights (UN Global Compact), which determine the most important cornerstones for our actions.
                            <br/><br/>
                            In order to avoid both Human Rights and environmental risks, the LkSG (Act on Corporate Due Diligence Obligations in Supply Chains) specifies several obligations concerning due diligence that companies must adhere to. These obligations include, among others, the implementation of risk analyses, the establishment of preventive measures, the adoption of remedial actions once legal violations have been identified as well as the establishment of a whistleblower system. The effectiveness of measures need to be checked and documented continuously.</GroupuiText>
                        </div>
                    </div>
                </div>
            </div>
            {/* dual teaser */}
            <div className="ui-content-teaser ui-deep-blue-bg">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Protecting Human Rights.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>“Clear responsibilities have been established throughout the Volkswagen Group”</GroupuiHeadline>
                            <GroupuiText>Clear responsibilities have been established throughout the Volkswagen Group within the framework of the 'Volkswagen three lines model' as a holistic governance, risk and compliance management system for the steering of corporate risks. The first line consists of the business units responsible for day-to-day operations. They need to identify risks at an early stage, analyse and mitigate these by adapting the necessary control measures. The areas for ensuring Human Rights and environmental due diligence are, among others, procurement or, in own business, human resources and environment.</GroupuiText>
                        </div>
                        <div className="ui-column">
                            <GroupuiText>The second line is responsible for controlling and advising the first line which includes, among others, the area of compliance.
                            <br/><br/>
                            The third line consists of internal audit as the objective auditing body.
                            <br/><br/>
                            The Human Rights Officer primarily takes care of the tasks as laid out in § 4 (3) of the LkSG (Act on Corporate Due Diligence Obligations in Supply Chains). This includes the monitoring of the adequacy and effectiveness of risk management in the first and second lines and the implementation of risk-based controls.</GroupuiText>
                            <a href="/group/whistleblower" className="cta">Whistleblowers</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ui-content-teaser">
                <div className="ui-inner">
                    <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Declaration on Social Rights.</GroupuiHeadline>
                    <div className="ui-columns">
                        <div className="ui-column">
                            <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>At Volkswagen AG we follow up hints with the necessary seriousness.</GroupuiHeadline>
                            <GroupuiText>Potential violations of Human Rights as well as environmental risks in own business as well as by direct and indirect suppliers and other business partners can be reported to the Investigation Office at all times. This is also relevant for any other hints that may require immediate action. The Investigation Office will inform the responsible departments who will process the issue accordingly. This includes in particular taking the necessary measures to minimize or end violations and/or risks in case of confirmed initial suspicions.
                            <br/><br/>
                            Further information regarding the procedural principles of the Volkswagen Group Complaints Procedure can be found below.</GroupuiText>
                            <div className="btns">
                                <a href="https://www.volkswagen-group.com/en/publications/more/declaration-on-social-rights-1869" target="_blank" rel="noreferrer" className="cta">Download</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}
