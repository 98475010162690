// import { useRouteError } from "react-router-dom";

import Banner from '../components/Banner';

export default function Page(){
    // const error = useRouteError();
	return (
        <div className="page error">
            <Banner
                video={`${process.env.PUBLIC_URL}/assets/video/VWG_Layers_darkgreen.mp4`}
                title="Page doesn't exist"
                body="We couldn't find a matching page, please continue your journey below."
                cta={{text:"Return to site",link:'/'}}
                dim
                full/>
        </div>
	);
}
